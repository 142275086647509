/**
 * account模块接口列表
 */
import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const account = {
    // 微信登录
    webWechatLogin(params) {
        return axios.post(`${base.baseURL}/api/Account/WebWechatLogin`, params);
    },
    // 获取验证码
    // 接口返回JPEG文件的二进制流，设置请求数据类型：responseType:'arraybuffer'
    captcha(mobile) {
        return axios.get(`${base.baseURL}/api/Account/Captcha?mobile=${mobile}`, { responseType: 'arraybuffer' });
    },
    // 手机登陆
    mobileLogin(params) {
        return axios.post(`${base.baseURL}/api/Account/MobileLogin`, params);
    },
    // 获取短信验证码
    sendSms(params) {
        return axios.post(`${base.baseURL}/api/Account/SendSms`, params);
    },
    // 注销用户(删除用户账户和内容信息)
    logOff() {
        return axios.post(`${base.baseURL}/api/Account/LogOff`);
    },
}
export default account;
