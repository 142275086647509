/**
 * config模块接口列表
 */
import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const config = {
    // 保存配置
    saveConfig(params) {
        return axios.post(`${base.baseURL}/api/Config/SaveConfig/SaveConfig`, params);
    },
    // 获取配置
    getConfig(type) {
        return axios.get(`${base.baseURL}/api/Config/GetConfig/GetConfig/${type}`);
    },
}
export default config;
