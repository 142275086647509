import { createApp, h } from "vue";
import App from "./App.vue";
import { router } from "./router"; // 导入路由文件
import store from "./store"; // 导入vuex文件
import api from "./api"; // 导入api接口
// import ElementPlus from "element-plus"; //导入element-plus框架
// require("@/assets/css/index.css");
// import * as ElIconModules from '@element-plus/icons'// 统一导入el-icon图标
// import { transElIconName } from './utils/utils.js'// 导入转换图标名称的函数
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 主题CSS
require("@/assets/css/default.scss");
require("@/assets/css/dark.scss");
// require("@/assets/css/dark2.scss");
// require("@/assets/css/dark3.scss");
// svg图标注册
import "@/svgIcon/index";
import svgIcon from "@/svgIcon/index.vue";

// Require Froala Editor js file.
require("froala-editor/js/froala_editor.pkgd.min.js");

// Require Froala plugins js files.
require("froala-editor/js/plugins/paragraph_style.min.js");
require("froala-editor/js/plugins/paragraph_format.min.js");
require("froala-editor/js/plugins/colors.min.js");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/js/plugins/font_size.min.js");
require("froala-editor/js/plugins/link.min.js");
require("froala-editor/js/plugins/image.min.js");
require("froala-editor/js/plugins/line_height.min.js");
// require('froala-editor/js/plugins/inline_class.min.js')
// require('froala-editor/js/plugins/inline_style.min.js')
require("froala-editor/js/plugins/lists.min.js");
// require('froala-editor/js/plugins/align.min.js')
// require('froala-editor/js/third_party/image_tui.min.js')
// require('froala-editor/js/plugins/markdown.min.js')
// require('froala-editor/js/plugins/print.min.js')
// require('froala-editor/js/plugins/quick_insert.min.js')

// Require Froala language js file.
// require('froala-editor/js/languages/zh_cn.js')
require("@/components/froala-editor/js/languages/zh_cn.js");

// Require Froala Editor css files.
require("froala-editor/css/froala_style.min.css");

require("@/components/froala-editor/css/froala_editor.pkgd.min.css");
require("@/components/froala-editor/css/themes/dark.css");
// plugins
require("@/components/froala-editor/js/plugins/save.min.js");
require("@/components/froala-editor/js/plugins/image.min.js");
require("@/components/froala-editor/js/plugins/segment_spacing.js");

// require('@/components/froala-editor/js/plugins/html2pdf.bundle.js')
// require('@/components/froala-editor/js/plugins/mathtype-froala3/wiris')
import VueFroala from "@/components/froala-editor/vue-froala.js";

// jsBridge
import Bridge from "@/utils/jsBridge.js";

const app = createApp({
  render: () => h(App),
});

// 设置全局变量api
app.config.globalProperties.$api = api;

// jsBridge
app.config.globalProperties.$bridge = Bridge;

// const MathLive = require("mathlive");
// app.config.globalProperties.$MathLive = MathLive;

// 统一注册el-icon图标
// for (let iconName in ElIconModules) {
//     app.component(transElIconName(iconName), ElIconModules[iconName])
// }
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component)
// }

// 屏蔽警告信息
app.config.warnHandler = () => null;

app.use(router);
app.use(store);
// app.use(ElementPlus);
app.use(VueFroala);
app.component("svg-icon", svgIcon);
app.mount("#app");
