import { createRouter, createWebHistory } from "vue-router";

// const index = () => import("@/views/pc/index")
// const login = () => import("@/views/pc/login")
// const article = () => import("@/views/pc/article")
// // const article = () => import("@/views/pc/article/oldindex.vue")
// const mind = () => import("@/views/pc/mind")
// const download = () => import("@/views/pc/index/download.vue")
// const show = () => import("@/views/pc/index/show.vue")
// const about = () => import("@/views/pc/index/about.vue")
// const privacy = () => import("@/views/pc/index/privacy.vue")
// const agreement = () => import("@/views/pc/index/agreement.vue")
// const version = () => import("@/views/pc/index/version.vue")
const mindmap = () => import("@/views/app/mindmap");
const editor = () => import("@/views/app/editor");
const textarea = () => import("@/views/app/editor/textarea.vue");
// const refresh = () => import("@/views/pc/refresh")
// const articleold = () => import("@/views/pc/article/indexold.vue")
// const oldtonew = () => import("@/views/pc/article/oldtonew.vue")
// 删除用户信息（测试用，生产环境禁开）
// const logoff = () => import("@/views/pc/account/logoff.vue")

const routes = [
  { path: "/", redirect: "@/views/app/editor" },
  {
    path: "/mindmap/:token",
    name: "mindmaps",
    component: mindmap,
  },
  {
    path: "/mindmap/:token/:id",
    name: "mindmaps",
    component: mindmap,
  },
  {
    path: "/mindmap/:token/:id/:theme",
    name: "mindmap",
    component: mindmap,
  },
  {
    path: "/mindmap/:token/:id/:theme/:bgColor",
    name: "mindmapc",
    component: mindmap,
  },
  {
    path: "/editor/:token/:id",
    name: "editors",
    component: editor,
  },
  {
    path: "/editor/:token/:id/:theme",
    name: "editor",
    component: editor,
  },
  {
    path: "/editor/:token/:id/:theme/:bgColor",
    name: "editorc",
    component: editor,
  },
  {
    path: "/textarea",
    name: "textarea",
    component: textarea,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
