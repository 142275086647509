/**
 * article模块接口列表
 */
import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const article = {
    // 用户所有文档(平铺)
    articleList() {
        return axios.get(`${base.baseURL}/api/Article/ArticleList`);
    },
    // 获取子目录
    childrenList(articleId) {
        return axios.get(`${base.baseURL}/api/Article/ChildrenList/${articleId}`);
    },
    // 获取文档树(向上溯源)
    articelTree(articleId) {
        return axios.get(`${base.baseURL}/api/Article/ArticelTree/${articleId}`);
    },
    // 获取此目录下完整文档树
    fullArticleTree(articleId) {
        return axios.get(`${base.baseURL}/api/Article/FullArticleTree/${articleId}`);
    },
    // 内容详情
    articleDetail(articleId) {
        return axios.get(`${base.baseURL}/api/Article/ArticleDetail?articleId=${articleId}`);
    },
    // 新增或修改内容目录信息
    addOrUpdateArticleCatalog(params) {
        return axios.post(`${base.baseURL}/api/Article/AddOrUpdateArticleCatalog`, params);
    },
    // 重命名标题
    renameArticle(params) {
        return axios.post(`${base.baseURL}/api/Article/RenameArticle`, params);
    },
    // 修改内容
    updateContent(params) {
        return axios.post(`${base.baseURL}/api/Article/UpdateContent`, params);
    },
    // 最新文档（仅十条）
    latestArticleList() {
        return axios.get(`${base.baseURL}/api/Article/LatestArticleList`);
    },
    // 删除文档
    removeArticle(articleId) {
        return axios.post(`${base.baseURL}/api/Article/RemoveArticle/${articleId}`);
    },
    // 批量更新排序
    batchUpdateSort(params) {
        return axios.post(`${base.baseURL}/api/Article/BatchUpdateSort`, params);
    },
    // 用户回收站所有文档(平铺)
    unArticleList() {
        return axios.get(`${base.baseURL}/api/Article/UnArticleList`);
    },
    // 还原文档
    activateArtilce(params) {
        return axios.post(`${base.baseURL}/api/Article/ActivateArtilce`, params);
    },
    // 删除回收站文档(永久删除)
    deleteUnative(params) {
        return axios.post(`${base.baseURL}/api/Article/DeleteUnative`, params);
    },
    // 获取历史记录(最多只能取100条)
    historyArticleList(articleId) {
        return axios.get(`${base.baseURL}/api/HistoryArticle/ArticleList?ArticleId=${articleId}`);
    },
    // 获取历史记录的详情(id:历史记录的id)
    historyArticleDetail(id) {
        return axios.get(`${base.baseURL}/api/HistoryArticle/ArticleDetail/${id}`);
    },
    // 复原文档
    historyRecoverArticle(id) {
        return axios.post(`${base.baseURL}/api/HistoryArticle/RecoverArticle/${id}`);
    },
    //更新文档图标
    updateIconType(params) {
        return axios.post(`${base.baseURL}/api/Article/UpdateIconType`, params);
    },

    // 引用列表
    TipList() {
        return axios.get(`${base.baseURL}/api/Article/TipList`);
    },

    // 保存引用信息
    saveTip(params) {
        return axios.post(`${base.baseURL}/api/Article/SaveTip`, params);
    },

    // 移除 引用tip
    removeTip(id) {
        return axios.post(`${base.baseURL}/api/Article/RemoveTip/${id}`);
    },

    // 复制文档
    cloneArticle(params) {
        return axios.post(`${base.baseURL}/api/Article/CloneArticle`, params);
    },
}
export default article;
