/**
 * upload模块接口列表
 */
import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const upload = {

    // 获取oss上传签名信息
    createOssSignature(fileName) {
        return axios.get(`${base.baseURL}/api/Upload/CreateOssSignature?fileName=${fileName}`);
    },
}
export default upload;
