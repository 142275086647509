import { createStore, storeKey } from "vuex";
// import axios from 'axios';
import config from "/package.json";
let sys = "win";
let sUserAgent = navigator.userAgent.toLocaleLowerCase();
let isWin = sUserAgent.indexOf("windows") > -1;
let isMac = sUserAgent.indexOf("mac") > -1;
var isLinux = sUserAgent.indexOf("linux") > -1;
if (isWin) {
  sys = "windows";
} else if (isMac) {
  sys = "mac";
} else if (isLinux) {
  sys = "linux";
}
export default createStore({
  // 声明变量
  state: {
    // token
    mindmap_token: "",
    // 平台设备版本请信息|平台(1:pc,101:ios,201:android)|自定义|系统版本|
    mindmap_device: "|101|" + config.version + "|" + sys,
    mobile: "",
    isLogin: false,
    content: "",
    expandedList: [],
    bgColor: "#FFFFFF",
  },
  // 修改变量（state不能直接赋值修改，只能通过mutations）
  mutations: {
    setBgColor(state, bgColor) {
      state.bgColor = bgColor;
      localStorage.setItem("bgColor", bgColor);
    },
    setToken(state, token) {
      state.mindmap_token = token;
      state.isLogin = true;
      localStorage.setItem("mindmap_token", token);
      localStorage.setItem("isLogin", true);
    },
    delToken(state) {
      state.mindmap_token = null;
      state.mobile = null;
      state.isLogin = false;
      localStorage.removeItem("mindmap_token");
      localStorage.removeItem("mobile");
      localStorage.removeItem("isLogin");
    },
    setMobile(state, mobile) {
      state.mobile = mobile;
      localStorage.setItem("mobile", mobile);
    },
    changeContent(state, content) {
      state.content = content;
    },
    changeExpandedList(state, content) {
      state.expandedList = content;
    },
  },
  // mutations的值由actions传入
  actions: {
    // 参数一：自带属性，参数二：新值
    // setName(context, value) {
    //   // 修改getname的值
    //   context.commit('getname', value)
    // },
    // getA(context) {
    //   // 返回一个Promise函数
    //   return new Promise((reslove, reject) => {
    //     // 请求
    //     axios.get('https://api.url').then(res => {
    //       // 修改getAData的值
    //       context.commit('getAData', res)
    //       reslove(res)
    //     }).catch(res => { reject(res) })
    //   })
    // },
    // getB(context) {
    //   return new Promise((reslove, reject) => {
    //     axios.get('https://api.url').then(res => {
    //       context.commit('getBData', res)
    //       reslove(res)
    //     }).catch(res => { reject(res) })
    //   })
    // }
  },
  modules: {},
});
