/**
 * api接口的统一出口
 */

// 账户模块接口
import account from '@/api/account';
// 设置模块接口
import config from '@/api/config';
// 文章模块接口
import article from '@/api/article';
// 用户模块接口
import upload from '@/api/upload';
// 用户模块接口
import user from '@/api/user';

// 导出接口
export default {
    account,
    config,
    article,
    upload,
    user,
    // ……
}
