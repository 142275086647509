/**
 * 接口域名的管理
 */
const base = {
  // 生产
  baseURL: "https://api.moxingshu.com.cn",
  // 预发布
  // baseURL: 'https://pre-api.moxingshu.com.cn',
  // 测试
  // baseURL: "http://apidev.mindmap.x-tral.cn",
  otherURL: "",
};
export default base;
